import { auth } from '../services';
import { history } from '../helpers';

export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';

const authenticationRequest = (token) => {
    return {
        type: AUTHENTICATION_REQUEST,
        token,
    };
};

const authenticationSuccess = (user) => {
    return {
        type: AUTHENTICATION_SUCCESS,
        user,
    };
};

const authenticationLogout = () => {
    return {
        type: AUTHENTICATION_LOGOUT,
    };
};

export function failed(error) {
    return {
        type: AUTHENTICATION_ERROR,
        error,
    };
}

export function authenticate(token) {
    return dispatch => {
        dispatch(authenticationRequest(token));

        auth.authenticate(token)
            .then(user => {
                dispatch(authenticationSuccess(user));
                history.push('/');
            })
            .catch(err => dispatch(failed(err)))
    };
}

export function logout() {
    return dispatch => {
        auth.logout();
        dispatch(authenticationLogout());
        history.push('/');
    };
}


