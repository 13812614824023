import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// Icons
import BarChartIcon from '@material-ui/icons/BarChart';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';
import LayersIcon from '@material-ui/icons/Layers';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
// Pages
import Analysis from '../../pages/Analysis';
import Integrations from '../../pages/Integrations';
import Overview from '../../pages/Overview';
import Settings from '../../pages/Settings';

import { auth } from '../../services';

import { authentication } from '../../actions';

const routes = [
    {
        primary: true,
        path: '/overview',
        sidebarName: 'Overview',
        icon: DashboardIcon,
        component: Overview,
    },
    {
        primary: true,
        path: '/analysis',
        sidebarName: 'Analysis',
        icon: BarChartIcon,
        component: Analysis,
    },
    {
        primary: true,
        path: '/integrations',
        sidebarName: 'Integrations',
        icon: LayersIcon,
        component: Integrations,
    },
    {
        path: '/settings',
        sidebarName: 'Settings',
        icon: SettingsIcon,
        component: Settings,
    },
    {
        redirect: true,
        path: '/',
        to: '/overview',
    }
];

class Layout extends Component {
    state = {
        open: false,
    };

    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }

    render() {
        if (!auth.isAuthenticated()) {
            return (
                <Redirect to={ { pathname: '/login', state: { from: this.props.location } } }/>
            );
        }

        const { classes } = this.props;

        return (
            <div className={ classes.root }>
                <CssBaseline/>
                <AppBar
                    position='absolute'
                    className={ classNames(classes.appBar, this.state.open && classes.appBarShift) }
                >
                    <Toolbar disableGutters={ !this.state.open } className={ classes.toolbar }>
                        <IconButton
                            color='inherit'
                            aria-label='Open drawer'
                            onClick={ this.handleDrawerOpen }
                            className={ classNames(classes.menuButton, this.state.open && classes.menuButtonHidden) }
                        >
                            <MenuIcon/>
                        </IconButton>
                        <IconButton
                            color='inherit'
                            aria-label='Close drawer'
                            onClick={ this.handleDrawerClose }
                            className={ classNames(classes.menuButton, !this.state.open && classes.menuButtonHidden) }
                        >
                            <ChevronLeftIcon/>
                        </IconButton>
                        <Typography
                            component='h1'
                            variant='h6'
                            color='inherit'
                            noWrap
                            className={ classes.title }
                        >
                            Dashboard
                        </Typography>
                        <Button color='secondary' variant='contained' onClick={ this.handleLogout }>
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant='permanent'
                    classes={ {
                        paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    } }
                    open={ this.state.open }
                >
                    <div className={ classes.toolbarIcon }>
                        <HomeIcon/>
                    </div>
                    <Divider/>
                    <List>
                        {
                            routes.map((route, key) => {
                                if (route.redirect || !route.primary) return null;
                                return (
                                    <NavLink
                                        to={ route.path }
                                        key={ key }
                                        className={ classes.sidebarItem }
                                        activeClassName='active'
                                    >
                                        <ListItem button>
                                            <ListItemIcon>
                                                <route.icon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ route.sidebarName }/>
                                        </ListItem>
                                    </NavLink>
                                );
                            })
                        }
                    </List>
                    <Divider className={ classes.pullToBottom }/>
                    <List>
                        {
                            routes.map((route, key) => {
                                if (route.redirect || route.primary) return null;
                                return (
                                    <NavLink
                                        to={ route.path }
                                        key={ key }
                                        className={ classes.sidebarItem }
                                        activeClassName='active'
                                    >
                                        <ListItem button>
                                            <ListItemIcon>
                                                <route.icon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ route.sidebarName }/>
                                        </ListItem>
                                    </NavLink>
                                );
                            })
                        }
                    </List>
                </Drawer>
                <main className={ classes.content }>
                    <div className={ classes.appBarSpacer }/>
                    <Switch>
                        {
                            routes.map((route, key) => {
                                if (route.redirect) {
                                    return (
                                        <Redirect from={ route.path } to={ route.to } key={ key }/>
                                    );
                                }
                                return (
                                    <Route path={ route.path } render={ (props) => (
                                        <route.component classes={ classes } { ...props }/>
                                    ) } key={ key }/>
                                );
                            })
                        }
                    </Switch>
                </main>
            </div>
        );
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleLogout = () => {
        this.props.dispatch(authentication.logout());
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(Layout);
