const TOKEN_KEY = '_token';

class AuthService {
    constructor() {
        this._authenticated = false;
        this._token = localStorage.getItem(TOKEN_KEY);
    }

    isAuthenticated() {
        return this._authenticated;
    }

    getToken() {
        return this._token;
    }

    authenticate(token) {
        this._token = token;
        return this.fetch(`${ process.env.REACT_APP_API_BASE_URL }/v1/users/me`, {
            method: 'GET',
        }).then((user) => {
            this._authenticated = true;

            localStorage.setItem(TOKEN_KEY, token);

            return user;
        });
    }

    logout() {
        this._authenticated = false;
        this._token = '';

        localStorage.removeItem(TOKEN_KEY);
    }

    fetch(input, init) {
        if (!init.headers) {
            init.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${ this._token }`,
            }
        } else {
            init.headers['Authorization'] = `Bearer ${ this._token }`;
        }

        return fetch(input, init).then((response) => {
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if (response.status === 401) {
                        // auto logout if 401 response returned from api
                        this.logout();

                        window.location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        });
    }
}

const instance = new AuthService();

export default instance;
