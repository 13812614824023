import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { authentication } from '../../actions';

class Login extends React.Component {
    getParameterByName(name) {
        const match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

    componentDidMount() {
        const { dispatch } = this.props;
        let error = this.getParameterByName('error');
        if (error) {
            dispatch(authentication.failed(error));
            return;
        }

        let token = this.getParameterByName('token');
        if (token) {
            dispatch(authentication.authenticate(token));
            return;
        }
    }

    render() {
        const { classes, fetching } = this.props;
        let button;
        if (!fetching) {
            button = (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    href={ `${ process.env.REACT_APP_API_BASE_URL }/authenticate` }
                    className={ classes.loginBoxGoogleButton }
                >
                    <FontAwesomeIcon icon={ ['fab', 'google'] } className={ classes.leftIcon }/>
                    Sign in with Google
                </Button>
            );
        } else {
            button = (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={ true }
                    className={ classes.loginBoxGoogleButton }
                >
                    <CircularProgress size={ 20 } className={ classes.progressIcon }/>
                    Authenticating...
                </Button>
            );
        }

        return (
            <main className={ classes.loginBox }>
                <CssBaseline/>
                <Paper className={ classes.loginBoxPaper }>
                    <Avatar className={ classes.loginBoxAvatar }>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={ classes.loginBoxForm }>
                        { button }
                    </form>
                </Paper>
            </main>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        fetching: authentication.fetching,
    };
}

export default connect(mapStateToProps)(Login);

