import { authentication } from '../actions';

const { AUTHENTICATION_REQUEST, AUTHENTICATION_SUCCESS, AUTHENTICATION_ERROR, AUTHENTICATION_LOGOUT } = authentication;

const INITIAL_STATE = {
    fetching: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTHENTICATION_REQUEST:
            return Object.assign({}, state, {
                fetching: true,
            });
        case AUTHENTICATION_SUCCESS:
            return Object.assign({}, state, {
                fetching: false,
                user: action.user,
            });
        case AUTHENTICATION_ERROR:
            return Object.assign({}, state, {
                fetching: false,
                error: action.error,
            });
        case AUTHENTICATION_LOGOUT:
            return Object.assign({}, state, {
                fetching: false,
                user: null,
            });
        default:
            return state
    }
}
