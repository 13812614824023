import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { store } from './helpers';
import { authentication } from './actions';
import { auth } from './services';
import App from './pages/App';

library.add(fab);

const token = auth.getToken();
if (token !== null) {
    store.dispatch(authentication.authenticate(token));
}

ReactDOM.render(
    <Provider store={ store }>
        <App/>
    </Provider>,
    document.getElementById('root')
);
