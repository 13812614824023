import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

class Integrations extends React.Component {
    render() {
        return (
            <Typography variant='body1'>Integrations</Typography>
        );
    }
}

Integrations.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps)(Integrations);
