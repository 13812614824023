import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Route, Router, Switch } from 'react-router-dom';

import { history } from '../../helpers';
import { styles } from '../../theme';
import Login from '../Login';
import { Layout } from '../../components';

class App extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Router history={ history }>
                <div className={ classes.root }>
                    <Switch>
                        <Route path="/login" render={ (props) => (
                            <Login classes={ classes } { ...props }/>
                        ) }/>
                        <Route path="/" render={ (props) => (
                            <Layout classes={ classes } { ...props }/>
                        ) }/>
                    </Switch>
                </div>
            </Router>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(withStyles(styles)(App));
